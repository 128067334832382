// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Autocomplete,
  Card,
  CircularProgress,
  Divider,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import SinDatos from "components/Carteles/SinDatos";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { ConfirmarContext } from "context/ConfirmarContext";
import { getUproColor } from "utils/colors";
import { UserContext } from "context/user";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import MEDIDAS from "utils/medidas";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflowY: "auto",
  p: 3,
};

const columnsManoDeObra = [
  { name: "nombre", desc: "Nombre", align: "left", noOrder: true },
  { name: "horas", desc: "Horas", align: "left", noOrder: true, width: "150px" },
  { name: "moneda", desc: "Tipo de Moneda", align: "left", noOrder: true, width: "150px" },
  { name: "precio", desc: "Precio por hora", align: "left", noOrder: true, width: "150px" },
  { name: "cotizacion", desc: "Cotización", align: "left", noOrder: true, width: "150px" },
  { name: "action", desc: " ", align: "left", noOrder: true, width: "1%" },
];

const columnsMaterial = [
  { name: "material", desc: "Material", align: "left", noOrder: true },
  { name: "cantidad", desc: "Cantidad", align: "left", noOrder: true, width: "150px" },
  { name: "moneda", desc: "Tipo de Moneda", align: "left", noOrder: true, width: "150px" },
  { name: "precio", desc: "Precio por unidad", align: "left", noOrder: true, width: "150px" },
  { name: "cotizacion", desc: "Cotización", align: "left", noOrder: true, width: "150px" },
  { name: "action", desc: " ", align: "left", noOrder: true, width: "1%" },
];

function ManoDeObraItem({ manoDeObra, handleUpdate, handleDelete, pt, pb,cotizaciones }) {
  const { borderWidth } = borders;
  const { size, fontWeightBold } = typography;

  const [materialData, setMaterialData] = useState({
    ...manoDeObra,
  });

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setMaterialData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    handleUpdate(materialData);
  }, [materialData]);

  return (
    <TableRow hover>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        pl={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftInput
          label="nombre"
          value={materialData?.nombre}
          name="nombre"
          onChange={handleChanges}
        />
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInput
            label="horas"
            type="number"
            value={materialData?.horas}
            name="horas"
            onChange={handleChanges}
            icon={{
              component: "schedule",
              direction: "left",
            }}
            error={materialData?.horas.length > 0 && materialData?.horas <= 0}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <Select
        name="tipoMoneda"
        value={materialData?.tipoMoneda || ""}
        onChange={(event)=>{
            setMaterialData({
                ...materialData,
                tipoMoneda: event.target.value,
                cotizacion: cotizaciones?.find(ctz => ctz?.nombre === event.target.value)?.venta || (event.target.value === "Pesos" ? cotizaciones?.find(ctz => ctz?.nombre === "Dolar Blue")?.venta : 0),
            })
        }}
        >
            <MenuItem value="Pesos">Pesos</MenuItem>
            {
                cotizaciones?.map((ctz) => {
                    return (
                        <MenuItem key={ctz?.nombre} value={ctz?.nombre}>
                            {ctz?.nombre}
                        </MenuItem>
                    )
                }) 
            }
        </Select>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
            label="precio"
            value={materialData?.precio}
            name="precio"
            onChange={handleChanges}
            icon={{
              component: "attach_money",
              direction: "left",
            }}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
          name="cotizacion"
          label="cotizacion"
          value={materialData?.cotizacion || ""}
          onChange={handleChanges}
            icon={{
              component: "currency_exchange",
              direction: "left",
            }}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        pr={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftButton color="error" circular iconOnly onClick={handleDelete}>
            <Icon>delete</Icon>
          </SoftButton>
        </SoftTypography>
      </SoftBox>
    </TableRow>
  );
}

ManoDeObraItem.propTypes = {
  manoDeObra: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  pb: PropTypes.number,
  pt: PropTypes.number,
  cotizaciones: PropTypes.array.isRequired,
};

function MaterialesItem({ material, handleUpdate, handleDelete, pt, pb ,cotizaciones}) {
  const { borderWidth } = borders;
  const { size, fontWeightBold } = typography;

  const [materialData, setMaterialData] = useState({
    ...material,
  });

  const { data: productos } = useQuery(
    gql`
      query getProductos {
        productos {
          id
          nombre
          precio
        }
      }
    `
  );

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setMaterialData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    handleUpdate(materialData);
  }, [materialData]);

  useEffect(() => {
    if (materialData?.material) {
      setMaterialData({
        ...materialData,
        precio: materialData?.material?.precio || "",
      });
    }
  }, [materialData?.material]);

  return (
    <TableRow hover>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        pl={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <Select name="material" 
        value={
          materialData?.material?.id
            ? productos?.productos?.find((producto) => producto.id === materialData?.material?.id)
            : ""
        }
        // value={materialData?.material?.id} 
        onChange={handleChanges}>
          {productos?.productos?.map((producto) => (
            <MenuItem key={producto.id} value={producto}>
              {producto.nombre}
            </MenuItem>
          ))}
        </Select>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
            label="cantidad"
            value={materialData?.cantidad}
            name="cantidad"
            onChange={handleChanges}
            error={materialData?.cantidad === 0}
            icon={{
              component: "shopping_cart",
              direction: "left",
            }}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <Select
        name="tipoMoneda"
        value={materialData?.tipoMoneda || ""}
        onChange={(event)=>{
            setMaterialData({
                ...materialData,
                tipoMoneda: event.target.value,
                cotizacion: cotizaciones?.find(ctz => ctz?.nombre === event.target.value)?.venta || (event.target.value === "Pesos" ? cotizaciones?.find(ctz => ctz?.nombre === "Dolar Blue")?.venta : 0),
            })
        }}
        >
            <MenuItem value="Pesos">Pesos</MenuItem>
            {
                cotizaciones?.map((ctz) => {
                    return (
                        <MenuItem key={ctz?.nombre} value={ctz?.nombre}>
                            {ctz?.nombre}
                        </MenuItem>
                    )
                }) 
            }
        </Select>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
            label="precio"
            value={materialData?.precio}
            name="precio"
            onChange={handleChanges}
            icon={{
              component: "attach_money",
              direction: "left",
            }}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
          name="cotizacion"
          label="cotizacion"
          value={materialData?.cotizacion || ""}
          onChange={handleChanges}
            icon={{
              component: "currency_exchange",
              direction: "left",
            }}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        pr={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftButton color="error" circular iconOnly onClick={handleDelete}>
            <Icon>delete</Icon>
          </SoftButton>
        </SoftTypography>
      </SoftBox>
    </TableRow>
  );
}

MaterialesItem.propTypes = {
  material: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  pb: PropTypes.number,
  pt: PropTypes.number,
  cotizaciones: PropTypes.array.isRequired,
};

export default function ModalPlantilla({ open, handleClose, refetch, selectedPlantilla }) {
  const [servicio, setServicio] = useState({
    nombre: "",
    descripcion: "",
    nombreUnidad: "",
  });
  const { handleSnackbar } = useContext(MessageManager);
  
  const { borderWidth } = borders;
  const { size, fontWeightBold } = typography;
  const { light } = colors;

  const [arrayManoObra,setArrayManoObra] = useState([]);
  const [arrayMateriales,setArrayMateriales] = useState([]);
  
  const handleChanges = (e) => {
    setServicio({
      ...servicio,
      [e.target.name]: e.target.value,
    });
  };

  const {data: productos} = useQuery(
    gql`
      query getProductos {
        productos {
          id
          nombre
        }
      }
    `
  );

  const {data:cotizacion} = useQuery(
    gql`
        query getCotizaciones{
            cotizaciones{
            agencia
            compra
            decimales
            fechaActualizacion
            nombre
            variacion
            variacion
            venta
            }
        }
    `
  );

  const [savePlantillaFase, { loading: loadingSave }] = useMutation(
    gql`
      mutation savePlantillaFase($input: PlantillaFaseInput!) {
        savePlantillaFase(input: $input) {
          id
        }
      }
    `
  );

  const validarManoObra = (item)=>{
    return(
      item?.nombre != "" &&
      item?.nombre?.length > 0 &&
      item?.horas > 0 &&
      item?.horas != "" &&
      item?.precio > 0 &&
      item?.precio != ""
    )
  }

  const validarMateriales = (item)=>{
    return (
      item?.material?.id != "" &&
      item?.precio > 0 &&
      item?.precio != "" &&
      item?.cantidad > 0 &&
      item?.cantidad != ""
    )
  }

  const handleSaveServicio = () => {
    let dataManoObra = [];
    let dataMateriales = [];

    if(arrayManoObra.length > 0){
      arrayManoObra.forEach((manoObra)=>{
        // if(validarManoObra(manoObra)){
          dataManoObra.push({
            nombre: manoObra?.nombre,
            horas: manoObra?.horas ? Number(manoObra?.horas) : 0,
            tipoMoneda: manoObra?.tipoMoneda ? manoObra?.tipoMoneda : null,
            precio: manoObra?.precio ? parseFloat(manoObra?.precio) : 0,
            cotizacion: manoObra?.cotizacion ? parseFloat(manoObra?.cotizacion) : 0,
          })
        // }
      });
    }

    if(arrayMateriales.length > 0){
      arrayMateriales.forEach((material)=>{
        // if(validarMateriales(material)){
          dataMateriales.push({
            idProducto: material?.material?.id,
            nombre: material?.material?.nombre,
            cantidad: material?.cantidad,
            tipoMoneda: material?.tipoMoneda ? material?.tipoMoneda : null,
            precio: material?.precio ? parseFloat(material?.precio) : 0,
            cotizacion: material?.cotizacion ? parseFloat(material?.cotizacion) : 0,
          })
        // }
      });
    }

    savePlantillaFase({
          variables: {
            input: {
              nombre: servicio.nombre,
              descripcion: servicio.descripcion,
              nombreUnidad: servicio.nombreUnidad,
              manoDeObra: dataManoObra,
              materiales: dataMateriales
            },
          },
    })
      .then((res) => {
        refetch();
        handleSnackbar("Servicio guardado correctamente", "success");
        handleClose();
        resetModal();
      })
      .catch((e) => {
            handleSnackbar("Error al guardar el servicio", "error");
      });
    
  };
 
  const handleDeleteManoObra = (position)=>{
    setArrayManoObra(arrayManoObra.filter((item,index)=> index !== position));
  }
  const handleDeleteMaterial = (position)=>{
    setArrayMateriales(arrayMateriales.filter((item,index)=> index !== position));
  }

  const validarFormato = (tiempo)=>{
    const regex = /^([0-9]{1,2}):([0-5]\d)$/;

    return regex.test(tiempo);
  }

  const resetModal = ()=>{
    setServicio({
      nombre: "",
      descripcion: "",
      nombreUnidad: "",
    });
    setArrayManoObra([]);
    setArrayMateriales([]);
  }

  const validarServicio = ()=>{
    return (
      !servicio?.nombre ||
      !servicio?.descripcion ||
      !servicio?.nombreUnidad ||
      (arrayManoObra.length === 0 && arrayMateriales.length === 0) ||
      arrayManoObra.some(
        (item) => !item.nombre || !item.horas || !item.tipoMoneda || !item.precio || !item.cotizacion || item.horas <= 0
      ) ||
      arrayMateriales.some(
        (item) => !item.material || !item.cantidad || !item.tipoMoneda || !item.precio || !item.cotizacion 
      )
    )
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Información del Servicio</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox pb={4}>
              <Grid container spacing={2} mb={3}>
                <Grid item xs={12} sm={9}>
                  <InputLabel htmlFor="nombre">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nombre
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                      </SoftTypography>
                      </SoftTypography>
                  </InputLabel>
                      <SoftInput
                      label="nombre"
                      placeholder="Ej.: Colocación de cimientos para casa"
                      type="text"
                      name="nombre"
                      value={servicio?.nombre || ""}
                      error={ servicio?.nombre?.length > 0  && servicio?.nombre.trim() == "" }
                      onChange={handleChanges}
                      />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <InputLabel htmlFor="nombreUnidad">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nombre Unidad
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                      </SoftTypography>
                      </SoftTypography>
                  </InputLabel>
                  
                  <Select
                  label="nombreUnidad"
                  placeholder="Ej.: m2"
                  name="nombreUnidad"
                  value={servicio?.nombreUnidad || ""}
                  onChange={handleChanges}
                  >
                      {MEDIDAS.map((medida) => (
                          <MenuItem value={medida.abreviatura} key={medida.abreviatura}>
                              {medida.nombre}
                          </MenuItem>
                      ))}
                  </Select>
                </Grid>

                <Grid item xs={12} sm={12}>
                        <InputLabel htmlFor="descripcion">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Descripción
                            <SoftTypography variant="caption" fontWeight="bold" color="primary">
                                &nbsp;*
                            </SoftTypography>
                            </SoftTypography>
                        </InputLabel>
                            <SoftInput
                            label="descripcion"
                            placeholder="Inserte el descripcion de servicio"
                            type="text"
                            multiline
                            rows={3}
                            name="descripcion"
                            value={servicio?.descripcion || ""}
                            error={ servicio?.descripcion?.length > 0  && servicio?.descripcion.trim() == "" }
                            onChange={handleChanges}
                            />
                </Grid>   

              </Grid>
                
              {/* Mano de Obra - Inicio */}
              <SoftBox mb={3}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" >
                  <SoftTypography variant="h6">Mano de obra por unidad</SoftTypography>

                  <SoftButton
                    color="primary"
                    circular
                    onClick={() => {
                      let newArrayManoObra = [...arrayManoObra];
                      newArrayManoObra.push({
                        id: new Date().getTime(),
                        nombre: "",
                        horas: "",
                        tipoMoneda: "Pesos",
                        precio: "",
                        cotizacion: cotizacion?.cotizaciones?.find((ctz)=> ctz?.nombre == "Dolar Blue")?.venta || 0,
                      });
                      setArrayManoObra(newArrayManoObra);
                    }}
                  >
                    Agregar &nbsp;
                    <Icon>add</Icon>
                  </SoftButton>
                </SoftBox>

                <SoftBox my={2}>
                  {arrayManoObra.length > 0 ? (
                    <TableContainer>
                      <Table>
                        <SoftBox component="thead">
                          <TableRow>
                            {columnsManoDeObra.map(({ name, desc, align, width }, key) => {
                              let pl;
                              let pr;

                              if (key === 0) {
                                pl = 3;
                                pr = 3;
                              } else if (key === columnsManoDeObra.length - 1) {
                                pl = 3;
                                pr = 3;
                              } else {
                                pl = 1;
                                pr = 1;
                              }

                              return (
                                <SoftBox
                                  key={name}
                                  component="th"
                                  width={width || "auto"}
                                  pt={1.5}
                                  pb={1.25}
                                  pl={align === "left" ? pl : 3}
                                  pr={align === "right" ? pr : 3}
                                  textAlign={align}
                                  fontSize={size.xxs}
                                  fontWeight={fontWeightBold}
                                  color="secondary"
                                  opacity={0.7}
                                  borderBottom={`${borderWidth[1]} solid ${light.main}`}
                                >
                                  {desc ? desc.toUpperCase() : name.toUpperCase()}
                                </SoftBox>
                              );
                            })}
                          </TableRow>
                        </SoftBox>
                        <TableBody>
                          {arrayManoObra.map((manoDeObra, index) => {
                            return (
                              <ManoDeObraItem
                                key={manoDeObra.id}
                                manoDeObra={manoDeObra}
                                handleUpdate={(item) => {
                                  let newArrayManoObra = [...arrayManoObra];
                                  newArrayManoObra[index] = item;
                                  setArrayManoObra(newArrayManoObra);
                                }}
                                handleDelete={() => {
                                  let newArrayManoObra = [...arrayManoObra];
                                  newArrayManoObra.splice(index, 1);
                                  setArrayManoObra(newArrayManoObra);
                                }}
                                pb={index === arrayManoObra.length - 1 ? 2 : null}
                                pt={index === 0 ? 2 : null}
                                cotizaciones={cotizacion?.cotizaciones}
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <SinDatos />
                  )}
                </SoftBox>
              </SoftBox>  
              {/* Mano de Obra - Fin */}
                
              {/* Materiales - Inicio */}
              <SoftBox mb={3}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" >
                  <SoftTypography variant="h6">Materiales por unidad</SoftTypography>
                  <SoftButton
                    color="primary"
                    circular
                    onClick={() => {
                      let newArrayMateriales = [...arrayMateriales];
                      newArrayMateriales.push({
                        id: new Date().getTime(),
                        nombre: "",
                        cantidad: "",
                        tipoMoneda: "Pesos",
                        precio: "",
                        cotizacion: cotizacion?.cotizaciones?.find((ctz)=> ctz?.nombre == "Dolar Blue")?.venta || 0,
                      });
                      setArrayMateriales(newArrayMateriales);
                    }}
                  >
                    Agregar &nbsp;
                    <Icon>add</Icon>
                  </SoftButton>
                </SoftBox>

                <SoftBox my={2}>
                  {arrayMateriales.length > 0 ? (
                    <TableContainer>
                      <Table>
                        <SoftBox component="thead">
                          <TableRow>
                            {columnsMaterial.map(({ name, desc, align, width }, key) => {
                              let pl;
                              let pr;

                              if (key === 0) {
                                pl = 3;
                                pr = 3;
                              } else if (key === columnsMaterial.length - 1) {
                                pl = 3;
                                pr = 3;
                              } else {
                                pl = 1;
                                pr = 1;
                              }

                              return (
                                <SoftBox
                                  key={name}
                                  component="th"
                                  width={width || "auto"}
                                  pt={1.5}
                                  pb={1.25}
                                  pl={align === "left" ? pl : 3}
                                  pr={align === "right" ? pr : 3}
                                  textAlign={align}
                                  fontSize={size.xxs}
                                  fontWeight={fontWeightBold}
                                  color="secondary"
                                  opacity={0.7}
                                  borderBottom={`${borderWidth[1]} solid ${light.main}`}
                                >
                                  {desc ? desc.toUpperCase() : name.toUpperCase()}
                                </SoftBox>
                              );
                            })}
                          </TableRow>
                        </SoftBox>
                        <TableBody>
                          {arrayMateriales.map((material, index) => {
                            return (
                              <MaterialesItem
                                key={material.id}
                                material={material}
                                handleUpdate={(item) => {
                                  let newArrayMateriales = [...arrayMateriales];
                                  newArrayMateriales[index] = item;
                                  setArrayMateriales(newArrayMateriales);
                                }}
                                handleDelete={() => {
                                  let newArrayMateriales = [...arrayMateriales];
                                  newArrayMateriales.splice(index, 1);
                                  setArrayMateriales(newArrayMateriales);
                                }}
                                pb={index === arrayMateriales.length - 1 ? 2 : null}
                                pt={index === 0 ? 2 : null}
                                cotizaciones={cotizacion?.cotizaciones}
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <SinDatos />
                  )}
                </SoftBox>
              </SoftBox> 
              {/* Materiales - Fin */}
            </SoftBox>

            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="flex-end"
              alignItems="center"
            >
              
              <SoftBox
                display={{
                  xs: "flex-row",
                  sm: "flex",
                }}
                justifyContent="end"
                alignItems="center"
              >
                <SoftBox
                  mr={{
                    xs: 0,
                    sm: 2,
                  }}
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="primary"
                    circular
                    onClick={()=>{
                        handleClose();
                        resetModal();
                    }}
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                    &nbsp;Cancelar
                  </SoftButton>
                </SoftBox>
                <SoftBox
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="sistemasGris"
                    circular
                    disabled={validarServicio()}
                    onClick={handleSaveServicio}
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    {loadingSave ? (
                      <CircularProgress size={15} color="inherit" />
                    ) : (
                      <Icon sx={{ fontWeight: "light" }}>save</Icon>
                    )}
                    &nbsp;Guardar
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalPlantilla.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  selectedPlantilla: PropTypes.object,
};
