import * as React from "react";
import { Modal, Fade, Backdrop, Card, Tooltip, Icon } from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { useState } from "react";
import Loading from "components/Carteles/Loading";
import DataTable from "components/Tables/DataTable";
import SinDatos from "components/Carteles/SinDatos";
import { gql, useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import dataMovimientos from "./data/dataMovimientos";
import ModalNuevaRecepcion from "./components/ModalNuevaRecepcion";
import SoftProgress from "components/SoftProgress";
import ModalDetalle from "./components/ModalDetalle";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "80%", xxl: "60%" },
  overflow: "auto",
  pt: 3,
};

export default function ModalRecepcion({ open, handleClose,idOrden }) {
  const [rows, setRows] = useState([]);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [infoOrdenCompra,setInfoOrdenCompra] = useState({});
  const [avanceRecepcion,setAvanceRecepcion] = useState(0);
  const { columns, getRows } = dataMovimientos;
  const [openModalNuevoRemito, setOpenModalNuevoRemito] = useState(false);
  const [selectedRemito, setSelectedRemito] = useState();

  const [getOrden, { data,  loading: loadingOrden, error: errorOrden, refetch }] = useLazyQuery(gql`
        query getOrden($id: ID!) {
          ordenCompra(id: $id) {
          id
          fechaOrden 
          totalOrden
          proyecto{
            sucursal{
              id
              nombre 
              sede{
                id
                nombre
              }
            }
          }
          proyectoFase{
              id
              nombre
              cantidad
          }
          proveedor{
              id
              tipoProveedor
              razonSocial
              nombre
              apellido
          }
          detalle{
              id
              tipo
              nombre
              cantidad
              cantComprada
              precioCompra
              tipoMonedaCompra
              cotizacionCompra
              producto{
                id 
                nombre
              }
          }
          movimiento{
            id
            fechaEntrega
            fechaOrdenPago
            nroRemito
            proveedor{
              id
              tipoProveedor
              razonSocial
              nombre
              apellido
            }
            sucursalDestino{
              id
              nombre
              sede{
                id
                nombre
              }
            }
            movimientoDetalles{
              id
              producto{
                id
                nombre
              }
              precio
              cantidad
              superaOrdenProducto
            }
          }
        }
      }
      `, 
        {
          fetchPolicy: "no-cache"
        }
  );

  useEffect(() => {
      if(open){
        getOrden({
          variables: {
            id: idOrden
          }
        });
      }
  }, [open]);

  useEffect(() => {
    if (data) {
      if(data?.ordenCompra?.movimiento?.length > 0){
        setRows(getRows(data?.ordenCompra?.movimiento, handleOpenRemito));
        const recepciones = handleRecepciones(data?.ordenCompra);
        setInfoOrdenCompra(recepciones);       
      }else{
        setRows([]);
        setAvanceRecepcion(0);
        setInfoOrdenCompra(data?.ordenCompra);  
      }
    }else{
      setRows([]);
      setAvanceRecepcion(0);
    }
  }, [data]);

  const handleOpenRemito = (remito) => {
    let arrayMovimientos = [];

    data?.ordenCompra?.movimiento?.forEach((item) => {
      item?.movimientoDetalles?.forEach((element) => {
        if(arrayMovimientos.some((mov) => mov?.producto?.id == element?.producto?.id)){
          let index = arrayMovimientos.findIndex((mov) => mov?.producto?.id == element?.producto?.id);
          arrayMovimientos[index].totalRecibido += element?.cantidad;
        }else{
          arrayMovimientos.push({
            ...element,
            totalRecibido: element?.cantidad
          });
        }
      });
    });

    let arrayOrdenRecepcion = [];

    data?.ordenCompra?.detalle?.forEach((item) => {
      if(arrayMovimientos.some((mov)=> mov?.producto?.id == item?.producto?.id)){
        let movTotal = arrayMovimientos.find((mov) => mov?.producto?.id == item?.producto?.id);
        arrayOrdenRecepcion.push({
          ...item,
          totalAcumulado: movTotal?.totalRecibido,
        });
      }else{
        arrayOrdenRecepcion.push(item);
      }
    });

    setSelectedRemito({
      ...remito,
      detalle: arrayOrdenRecepcion
    });

    setOpenModalNuevoRemito(true);
  };

  const handleRecepciones = (ordenSelect)=>{
    let arrayMovimientos = [];

    ordenSelect?.movimiento?.forEach((item) => {
      item?.movimientoDetalles?.forEach((element) => {
        if(arrayMovimientos.some((mov) => mov?.producto?.id == element?.producto?.id)){
          let index = arrayMovimientos.findIndex((mov) => mov?.producto?.id == element?.producto?.id);
          arrayMovimientos[index].totalRecibido += element?.cantidad;
        }else{
          arrayMovimientos.push({
            ...element,
            totalRecibido: element?.cantidad
          });
        }
      });
    });

    let arrayOrdenRecepcion = [];
    let totalPedido = 0;

    ordenSelect?.detalle?.forEach((item) => {
      totalPedido += item?.cantComprada;
      if(arrayMovimientos.some((mov)=> mov?.producto?.id == item?.producto?.id)){
        let movTotal = arrayMovimientos.find((mov) => mov?.producto?.id == item?.producto?.id);
        if(movTotal?.totalRecibido < item?.cantComprada){
          arrayOrdenRecepcion.push({
            ...item,
            restante: item?.cantComprada - movTotal?.totalRecibido,
            ordenPrevia: true
          });
        }
      }else{
        arrayOrdenRecepcion.push(item);
      }
    });

    //calculo de avance de recepcion de materiales
    const totalMaterialesRecibidos = arrayMovimientos.reduce((acc, item) => acc + item?.totalRecibido, 0);
    const porcentajeAvance = parseFloat((totalMaterialesRecibidos / totalPedido) * 100).toFixed(2); 
    setAvanceRecepcion(Math.min(100, Math.max(Math.round(porcentajeAvance), 0)));

    return {
      ...ordenSelect,
      detalle: arrayOrdenRecepcion || []
    } 
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox px={3} display="flex" justifyContent="space-between" alignItems="center">
            <SoftTypography variant="h6">Recepción de materiales</SoftTypography>
            <SoftBox
              display="flex"
              alignItems="center"
              justifyContent="end"
              mt={{
                xs: 2,
                sm: 0,
              }}
            >
              <SoftBox
                display={{
                  xs: "block",
                  sm: "none",
                }}
              >
                <Tooltip title="Agregar recepción" placement="top">
                  <SoftBox>
                    <SoftButton
                      color="primary"
                      circular
                      iconOnly
                      onClick={() => setOpenModalAdd(true)}
                      disabled={avanceRecepcion === 100}
                    >
                      <Icon>add</Icon>
                    </SoftButton>
                  </SoftBox>
                </Tooltip>
              </SoftBox>
              <SoftBox
                  display={{
                    xs: "none",
                    sm: "block",
                  }}
                >
                  <Tooltip title="Agregar recepción" placement="top">
                    <SoftBox>
                      <SoftButton
                        color="primary"
                        circular
                        onClick={() => setOpenModalAdd(true)}
                        disabled={avanceRecepcion === 100}
                      >
                        <Icon>add</Icon>
                        &nbsp;Agregar
                      </SoftButton>
                    </SoftBox>
                  </Tooltip>
              </SoftBox>
            </SoftBox>
          </SoftBox>

          <Card sx={{ my: 2,mx: 3 }} >
            <SoftBox p={3}>
              <SoftBox display="flex" alignItems="center" justifyContent="space-between">
                <SoftBox>
                  <SoftBox display="flex" alignItems="center">
                    <SoftTypography variant="body2" fontWeight="medium" ml={1}>
                      Avance de recepción
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
                <SoftBox sx={{ width: "70%" }} textAlign="center">
                  <SoftBox px={2} display="flex-row" justifyContent="center">
                    <SoftTypography variant="body2" fontWeight="medium" mb={1}>
                      {avanceRecepcion}%
                    </SoftTypography>
                    <SoftBox
                      sx={{
                        width: "100%",
                      }}
                    >
                      <SoftProgress color="primary" variant="gradient" value={avanceRecepcion} />
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>

          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
            pt={2}
          >
            {loadingOrden ? (
              <Loading />
            ) : rows?.length > 0 ? (
              <DataTable
                columns={columns}
                rows={rows}
              />
            ) : (
              <SoftBox pb={2}>
                <SinDatos />
              </SoftBox>
            )}
          </SoftBox>
         
        <ModalNuevaRecepcion
          open={openModalAdd}
          handleClose={() => setOpenModalAdd(false)}
          selectedRemito={null}
          dataOrden={infoOrdenCompra}
          refetchProductos={refetch}
        />

        <ModalDetalle
         open={openModalNuevoRemito}
         handleClose={() => setOpenModalNuevoRemito(false)}
         selectedRemito={selectedRemito}
        />
        </Card>
      </Fade>
    </Modal>
  );
}

ModalRecepcion.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  idOrden: PropTypes.string,
};
