// React
import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import SinDatos from "components/Carteles/SinDatos";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import ModalAprobacion from "../ModalNewFaseTerceros/ModalAprobacion";
import ModalAddProveedor from "layouts/proveedor/components/ModalAdd";
import ModalFormaPago from "../ModalNewFaseTerceros/ModalFormaPago";
import ModalDiferenciaMonto from "../ModalNewFaseTerceros/ModalDiferenciaMonto";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflowY: "auto",
  p: 3,
};

const columnsConcepto = [
  { name: "nombre", desc: "Nombre", align: "left", noOrder: true },
  { name: "moneda", desc: "Tipo de Moneda", align: "left", noOrder: true, width: "150px" },
  { name: "precio", desc: "Precio", align: "left", noOrder: true, width: "150px" },
  { name: "cotizacion", desc: "Cotización", align: "left", noOrder: true, width: "150px" },
  { name: "action", desc: " ", align: "left", noOrder: true, width: "1%" },
];

const columnsPresupuesto = [
  { name: "nroPresupuesto", desc: "Numero", align: "left", noOrder: true, width: "8%" },
  { name: "nombre", desc: "Nombre", align: "left", noOrder: true },
  { name: "observacion", desc: "Observaciones", align: "left", noOrder: true,  },
  { name: "action", desc: " ", align: "left", noOrder: true, width: "2%" },
];

function ConceptoItem({ concepto, handleUpdate, handleDelete, pt, pb,cotizaciones,tipoMoneda }) {
  const { borderWidth } = borders;
  const { size, fontWeightBold } = typography;

  const [conceptoData, setConceptoData] = useState({
    ...concepto,
  });

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setConceptoData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    handleUpdate(conceptoData);
  }, [conceptoData]);

  return (
    <TableRow hover>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        pl={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftInput
          label="nombre"
          value={conceptoData?.nombre}
          name="nombre"
          onChange={handleChanges}
        />
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <Select
        name="tipoMoneda"
        value={conceptoData?.tipoMoneda || ""}
        onChange={(event)=>{
          setConceptoData({
            ...conceptoData,
            tipoMoneda: event.target.value,
            cotizacion: cotizaciones?.find(ctz => ctz?.nombre === event.target.value)?.venta || ((event.target.value === "Pesos" && tipoMoneda !== "Pesos" ) ? cotizaciones?.find(ctz => ctz?.nombre === tipoMoneda)?.venta : 1),
          })
        }}
        >
            <MenuItem value="Pesos">Pesos</MenuItem>
            {
                cotizaciones?.map((ctz) => {
                    return (
                        <MenuItem key={ctz?.nombre} value={ctz?.nombre}>
                            {ctz?.nombre}
                        </MenuItem>
                    )
                }) 
            }
        </Select>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
            label="precio"
            value={conceptoData?.precio}
            name="precio"
            onChange={handleChanges}
            icon={{
              component: "attach_money",
              direction: "left",
            }}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
          name="cotizacion"
          label="cotizacion"
          value={conceptoData?.cotizacion || ""}
          onChange={handleChanges}
            icon={{
              component: "currency_exchange",
              direction: "left",
            }}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        pr={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftButton color="error" circular iconOnly onClick={handleDelete}>
            <Icon>delete</Icon>
          </SoftButton>
        </SoftTypography>
      </SoftBox>
    </TableRow>
  );
}

ConceptoItem.propTypes = {
  concepto: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  pb: PropTypes.number,
  pt: PropTypes.number,
  cotizaciones: PropTypes.array.isRequired,
  tipoMoneda: PropTypes.string
};

function PresupuestoItem({ presupuesto, handleUpdate, handleDelete, pt, pb,listProveedores,refetchProveedor }) {
  const {handleSnackbar} = useContext(MessageManager);
  const { borderWidth } = borders;
  const { size, fontWeightBold } = typography;
  const fileRef = useRef();
  const [modalAprobacion,setModalAprobacion] = useState(false);
  const [openModalProveedores,setOpenModalProveedores]=useState(false);
  const [modalFormaPago,setModalFormaPago] = useState(false);

  const [presupuestoData, setPresupuestoData] = useState({
    ...presupuesto
  });

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setPresupuestoData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    handleUpdate(presupuestoData);
  }, [presupuestoData]);

  useEffect(() => {
    setPresupuestoData({...presupuesto});
  }, [presupuesto?.aprobado]);

  const handleGuardarMotivo = (motivo) => {
    setPresupuestoData((prevState) => ({
      ...prevState,
      aprobado: true,
      motivoAprobacion: motivo
    }))
    setModalAprobacion(false);
  }

  const handleGuardarFormaPago = (forma) => {
    setPresupuestoData((prevState) => ({
      ...prevState,
      ...forma
    }))
    setModalFormaPago(false);
  }

  return (
    <TableRow hover>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        pl={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        sx={{
          verticalAlign: "top",
        }}
      >
        <SoftBox display="flex" alignItems="center" gap={1}>
          <SoftInput
            label="nroPresupuesto"
            value={presupuestoData?.nroPresupuesto}
            name="nroPresupuesto"
            onChange={handleChanges}
          />          
        </SoftBox>
      </SoftBox>

      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftBox display="flex" alignItems="flex-start" gap={1}>
          <SoftBox display="flex" flexDirection="column" alignItems="flex-end" flexGrow={1}>
            <Select
            name="nombre"
            value={presupuestoData?.nombre ?
              listProveedores?.find((prov)=> prov?.id === presupuestoData?.nombre?.id) : ""
            }
            onChange={handleChanges}
            >
              {
                listProveedores?.map((prov)=> {
                  return (
                    <MenuItem key={prov?.id} value={prov}>
                      {prov?.razonSocial ? prov?.razonSocial : prov?.nombre ? prov?.nombre+" "+prov?.apellido : ""}
                    </MenuItem>
                  )
                })
              }
            </Select>
            <SoftBox>
              <InputLabel htmlFor="proveedor">
                <SoftTypography
                  variant="caption"
                  color="text"
                  onClick={() => setOpenModalProveedores(true)}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  Agregar proveedor &nbsp;
                  <Icon
                    sx={{
                      verticalAlign: "middle",
                    }}
                    color="primary"
                  >
                    settings
                  </Icon>
                </SoftTypography>
              </InputLabel>
            </SoftBox>
          </SoftBox>

          <SoftBox>
            <Tooltip title={presupuestoData?.aprobado ? "Aprobado" : "No aprobado"} placement="top" >
              <SoftButton
              variant="gradient"
              iconOnly
              circular
              color={presupuestoData?.aprobado ? "success" : "light"}
              onClick={()=>{
                if(!presupuestoData?.aprobado){
                  setModalAprobacion(true);
                }else{
                  setPresupuestoData((prevState) => ({
                    ...prevState,
                    aprobado: !presupuestoData?.aprobado,
                    motivoAprobacion: ""
                  }));
                }
              }}
              >
                <Icon 
                fontSize="medium"
                color={presupuestoData?.aprobado ? "dark" : "secondary"}
                >
                  check
                </Icon>
              </SoftButton>
            </Tooltip>
          </SoftBox> 
        </SoftBox>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        sx={{
          verticalAlign: "top",
        }}
        display="flex"
        alignItems="flex-start"
        gap={1}
      >
        <SoftInput
          label="observaciones"
          value={presupuestoData?.observaciones ? presupuestoData?.observaciones : ""}
          name="observaciones"
          onChange={handleChanges}
        />
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        pr={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "top",
        }}
      
      >
        <SoftBox display="flex" gap={1}>
          {
            !presupuestoData?.file ? (
              <>
                <Tooltip title="Adjuntar archivo" placement="top">
                  <SoftButton
                    variant="gradient"
                    color="success"
                    iconOnly
                    circular
                    onClick={() => {
                      fileRef.current.value = null;
                      fileRef.current.click();
                    }}
                  >
                    <Icon sx={{ fontWeight: "regular" }}>add</Icon>
                  </SoftButton>
                </Tooltip>
                <input
                  ref={fileRef}
                  type="file"
                  style={{ display: "none" }}
                  accept=".pdf, .docx, .jpg, .jpeg, .png"
                  onChange={(e) => {
                    const file = e.target.files[0];

                    if (file) {
                      const maxSize = 10 * 1024 * 1024; // 10MB en bytes
                  
                      if (file.size > maxSize) {
                        handleSnackbar("El archivo supera el tamaño máximo permitido de 10MB.", "error");
                        return;
                      }
                  
                      setPresupuestoData({
                        ...presupuestoData,
                        file: file,
                      });
                    }
                  }}
                />
              </>
            ) : (
              <>
                <Tooltip title="Borrar archivo" placement="top">
                  <SoftButton
                    variant="gradient"
                    color="error"
                    iconOnly
                    circular
                    onClick={() => {
                      setPresupuestoData({
                        ...presupuestoData,
                        file: null,
                      });
                    }}
                  >
                    <Icon sx={{ fontWeight: "regular" }}>folder_off</Icon>
                  </SoftButton>
                </Tooltip>
              </>
            )
          }
          <Tooltip title="Forma de pago" placement="top">
            <SoftButton color="primary" circular iconOnly 
            onClick={()=>setModalFormaPago(true)}
            >
              <Icon>attach_money</Icon>
            </SoftButton>
          </Tooltip>
          <Tooltip title="Eliminar" placement="top">
            <SoftButton color="error" circular iconOnly onClick={handleDelete}>
              <Icon>delete</Icon>
            </SoftButton>
          </Tooltip>
        </SoftBox>
      </SoftBox>
      <ModalAprobacion
      open={modalAprobacion}
      handleClose={()=>setModalAprobacion(false)}
      guardarMotivo={handleGuardarMotivo}
      />
      <ModalAddProveedor
       open={openModalProveedores}
       handleClose={() => {
        setOpenModalProveedores(false);
       }}
       data={listProveedores}
       refetch={refetchProveedor}
      />
      <ModalFormaPago
       open={modalFormaPago}
       handleClose={()=>setModalFormaPago(false)}
       guardarFormaPago={handleGuardarFormaPago}
       presupuestoData={presupuestoData}
      />
    </TableRow>
  );
}

PresupuestoItem.propTypes = {
  presupuesto: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  pb: PropTypes.number,
  pt: PropTypes.number,
  listProveedores: PropTypes.array,
  refetchProveedor: PropTypes.func
};

export default function ModalEditarFaseTerceros({ open, handleClose, guardarFase ,dataFase,tipoMoneda}) {
  const [servicio, setServicio] = useState();
  const { handleSnackbar } = useContext(MessageManager);
  const [arrayConceptos, setArrayConceptos] = useState([]);
  const [arrayPresupuestos, setArrayPresupuestos] = useState([]);
  const [alertDiferenciaMonto,setAlertDiferenciaMonto] = useState(false);
  const [montos,setMontos] = useState({
    presupuesto: 0,
    conceptos: 0
  });
  const { borderWidth } = borders;
  const { size, fontWeightBold } = typography;
  const { light } = colors;

  const {data:cotizacion} = useQuery(
    gql`
        query getCotizaciones{
            cotizaciones{
            agencia
            compra
            decimales
            fechaActualizacion
            nombre
            variacion
            variacion
            venta
            }
        }
    `
  );

  const { data: proveedores, refetch: refetchProveedores } = useQuery(
    gql`
      query getProveedores {
        proveedores {
          id
          razonSocial
          nombre
          apellido
        }
      }
    `
  );

  const handleChanges = (e) => {
    setServicio({
      ...servicio,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setServicio(dataFase);
    setArrayConceptos(dataFase?.concepto || []);
    setArrayPresupuestos(dataFase?.presupuesto || []);
  }, [dataFase]);

  const resetModal = () => {
    setServicio({});
    setArrayConceptos([]);
    setArrayPresupuestos([]);
  };
  
  const controlFaseTerceros = ()=>{
    if(arrayPresupuestos?.some((item)=> item?.aprobado == true)){
      let presupuestoAprobado = arrayPresupuestos?.find((item)=> item?.aprobado == true);

      let totalConceptos = arrayConceptos?.reduce((acc, concepto) => acc + concepto.precio, 0);

      if(parseFloat(totalConceptos) == parseFloat(presupuestoAprobado?.precio)){
        guardarFase({
          ...servicio,
          concepto: arrayConceptos,
          presupuesto: arrayPresupuestos,
        });
      }else{
        setAlertDiferenciaMonto(true);
        setMontos({
          presupuesto: presupuestoAprobado?.precio,
          conceptos: totalConceptos
        });
      }
    }else{
      guardarFase({
        ...servicio,
        concepto: arrayConceptos,
        presupuesto: arrayPresupuestos,
      });
    }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Información de la fase</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon
                      fontSize="medium"
                      onClick={() => {
                        resetModal();
                        handleClose();
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox pb={4}>
              <SoftBox mb={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <InputLabel htmlFor="nombre">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Nombre
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="nombre"
                      placeholder="Ej.: Colocación de cimientos para casa"
                      type="text"
                      name="nombre"
                      value={servicio?.nombre || ""}
                      error={servicio?.nombre?.length > 0 && servicio?.nombre.trim() == ""}
                      onChange={handleChanges}
                    />
                  </Grid>
              
                  <Grid item xs={12} sm={12}>
                    <InputLabel htmlFor="descripcion">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Descripción
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="descripcion"
                      placeholder="Inserte el descripcion de servicio"
                      type="text"
                      multiline
                      rows={3}
                      name="descripcion"
                      value={servicio?.descripcion || ""}
                      error={
                        servicio?.descripcion?.length > 0 && servicio?.descripcion.trim() == ""
                      }
                      onChange={handleChanges}
                    />
                  </Grid>
                </Grid>
              </SoftBox>

              <SoftBox mb={3}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                  <SoftTypography variant="h6">Conceptos</SoftTypography>
                  <SoftButton
                    color="primary"
                    circular
                    onClick={() => {
                      let newArrayConceptos = [...arrayConceptos];
                      newArrayConceptos.push({
                        id: new Date().getTime(),
                        nombre: "",
                        tipoMoneda: "Pesos",
                        precio: "",
                        cotizacion: cotizacion?.cotizaciones?.find((ctz)=> ctz?.nombre == tipoMoneda)?.venta || 1,
                      });
                      setArrayConceptos(newArrayConceptos);
                    }}
                  >
                    Agregar &nbsp;
                    <Icon>add</Icon>
                  </SoftButton>
                </SoftBox>
                <SoftBox my={2}>
                  {arrayConceptos.length > 0 ? (
                    <TableContainer>
                      <Table>
                        <SoftBox component="thead">
                          <TableRow>
                            {columnsConcepto.map(({ name, desc, align, width }, key) => {
                              let pl;
                              let pr;

                              if (key === 0) {
                                pl = 3;
                                pr = 3;
                              } else if (key === columnsConcepto.length - 1) {
                                pl = 3;
                                pr = 3;
                              } else {
                                pl = 1;
                                pr = 1;
                              }

                              return (
                                <SoftBox
                                  key={name}
                                  component="th"
                                  width={width || "auto"}
                                  pt={1.5}
                                  pb={1.25}
                                  pl={align === "left" ? pl : 3}
                                  pr={align === "right" ? pr : 3}
                                  textAlign={align}
                                  fontSize={size.xxs}
                                  fontWeight={fontWeightBold}
                                  color="secondary"
                                  opacity={0.7}
                                  borderBottom={`${borderWidth[1]} solid ${light.main}`}
                                >
                                  {desc ? desc.toUpperCase() : name.toUpperCase()}
                                </SoftBox>
                              );
                            })}
                          </TableRow>
                        </SoftBox>
                        <TableBody>
                          {arrayConceptos.map((concepto, index) => {
                            return (
                              <ConceptoItem
                                key={concepto.id}
                                concepto={concepto}
                                handleUpdate={(item) => {
                                  let newArrayConceptos = [...arrayConceptos];
                                  newArrayConceptos[index] = item;
                                  setArrayConceptos(newArrayConceptos);
                                }}
                                handleDelete={() => {
                                  let newArrayConceptos = [...arrayConceptos];
                                  newArrayConceptos.splice(index, 1);
                                  setArrayConceptos(newArrayConceptos);
                                }}
                                pb={index === arrayConceptos.length - 1 ? 2 : null}
                                pt={index === 0 ? 2 : null}
                                cotizaciones={cotizacion?.cotizaciones}
                                tipoMoneda={tipoMoneda}
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <SinDatos />
                  )}
                </SoftBox>
              </SoftBox>

              <SoftBox mb={3}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                  <SoftTypography variant="h6">Presupuestos</SoftTypography>
                  <SoftButton
                    color="primary"
                    circular
                    onClick={() => {
                      let newArrayPresupuestos = [...arrayPresupuestos];
                      newArrayPresupuestos.push({
                        id: new Date().getTime(),
                        nombre: "",
                        file: "",
                        aprobado: false,
                      });
                      setArrayPresupuestos(newArrayPresupuestos);
                    }}
                  >
                    Agregar &nbsp;
                    <Icon>add</Icon>
                  </SoftButton>
                </SoftBox>
                <SoftBox my={2}>
                  {arrayPresupuestos.length > 0 ? (
                    <TableContainer>
                      <Table>
                        <SoftBox component="thead">
                          <TableRow>
                            {columnsPresupuesto.map(({ name, desc, align, width }, key) => {
                              let pl;
                              let pr;

                              if (key === 0) {
                                pl = 3;
                                pr = 3;
                              } else if (key === columnsPresupuesto.length - 1) {
                                pl = 3;
                                pr = 3;
                              } else {
                                pl = 1;
                                pr = 1;
                              }

                              return (
                                <SoftBox
                                  key={name}
                                  component="th"
                                  width={width || "auto"}
                                  pt={1.5}
                                  pb={1.25}
                                  pl={align === "left" ? pl : 3}
                                  pr={align === "right" ? pr : 3}
                                  textAlign={align}
                                  fontSize={size.xxs}
                                  fontWeight={fontWeightBold}
                                  color="secondary"
                                  opacity={0.7}
                                  borderBottom={`${borderWidth[1]} solid ${light.main}`}
                                >
                                  {desc ? desc.toUpperCase() : name.toUpperCase()}
                                </SoftBox>
                              );
                            })}
                          </TableRow>
                        </SoftBox>
                        <TableBody>
                          {arrayPresupuestos.map((presupuesto, index) => {
                            return (
                              <PresupuestoItem
                                key={presupuesto.id}
                                presupuesto={presupuesto}
                                handleUpdate={(item) => {
                                  if(item?.aprobado != arrayPresupuestos[index]?.aprobado){
                                    let newArrayPresupuestos = [...arrayPresupuestos];
                                    if(!item?.aprobado){
                                      newArrayPresupuestos[index] = item;
                                    }else{
                                      newArrayPresupuestos = newArrayPresupuestos.map((presupuesto) => {
                                        return {...presupuesto, aprobado: presupuesto.id === item.id ? item.aprobado : false, motivoAprobacion: presupuesto.id === item.id ? item.motivoAprobacion : ""};
                                      });
                                    }
                                    setArrayPresupuestos(newArrayPresupuestos);
                                  }else{
                                    let newArrayPresupuestos = [...arrayPresupuestos];
                                    newArrayPresupuestos[index] = item;
                                    setArrayPresupuestos(newArrayPresupuestos);
                                  }
                                }}
                                handleDelete={() => {
                                  let newArrayPresupuestos = [...arrayPresupuestos];
                                  newArrayPresupuestos.splice(index, 1);
                                  setArrayPresupuestos(newArrayPresupuestos);
                                }}
                                pb={index === arrayPresupuestos.length - 1 ? 2 : null}
                                pt={index === 0 ? 2 : null}
                                listProveedores={proveedores?.proveedores}
                                refetchProveedor={refetchProveedores}
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <SinDatos />
                  )}
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="flex-end"
              alignItems="center"
            >
              <SoftBox
                display={{
                  xs: "flex-row",
                  sm: "flex",
                }}
                justifyContent="end"
                alignItems="center"
              >
                <SoftBox
                  mr={{
                    xs: 0,
                    sm: 2,
                  }}
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="primary"
                    circular
                    onClick={() => {
                      handleClose();
                      resetModal();
                    }}
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                    &nbsp;Cancelar
                  </SoftButton>
                </SoftBox>
                <SoftBox
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="sistemasGris"
                    circular
                    disabled={
                      !servicio?.nombre ||
                      !servicio?.descripcion ||
                      (arrayConceptos?.length === 0 || arrayPresupuestos?.length === 0) ||
                      arrayConceptos.some(
                        (item) => !item.nombre || !item.tipoMoneda || !item.precio || !item.cotizacion
                      ) ||
                      arrayPresupuestos.some(
                        (item) => !item.nombre || !item.file || !item.fechaPago || !item.tipoMoneda || !item.precio || (item.aprobado && !item.motivoAprobacion) 
                      )
                    }
                    onClick={() => {
                      controlFaseTerceros();
                      // guardarFase({
                      //   ...servicio,
                      //   concepto: arrayConceptos,
                      //   presupuesto: arrayPresupuestos,
                      // });
                    }}
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                    &nbsp;Guardar
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <ModalDiferenciaMonto
             open={alertDiferenciaMonto}
             handleClose={() => setAlertDiferenciaMonto(false)}
             montos={montos}
            />
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalEditarFaseTerceros.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  guardarFase: PropTypes.func,
  dataFase: PropTypes.object,
  tipoMoneda: PropTypes.string,
};
