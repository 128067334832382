// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Kaizen Dashboard components
import { Tooltip } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { formatMoneyPunto } from "utils/formatters";
import SoftInput from "components/SoftInput";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

function FaseItem({ index, fase, handleEdit, handleDelete, handleChangeFase }) {
  const [proveedorPresupuesto, setProveedoredorPresupuesto] = useState(null);
  // const getTotal = () => {
  //   let total = 0;

  //   if(fase?.nombreUnidad){

  //     const cantidad = parseInt(fase?.cantidad) || 0;
  //     const porcentaje = parseInt(fase?.porcentajeGanancia) || 0;
  //     let content = [
  //       ...(fase?.manoDeObra || []),
  //       ...(fase?.materiales || []),
  //       ...(fase?.concepto || []),
  //     ]
  //     content?.forEach((item) => {
  //       if (item.horas) {
  //         total += item.precio * item.horas * cantidad || 0;
  //       } else if (item.cantidad) { 
  //         total += item.precio * item.cantidad * cantidad || 0;
  //       } else {
  //         total += item.precio || 0;
  //       }
  //     });
  //     total += total * (porcentaje / 100) || 0;
  //     return total;

  //   }else{

  //     const porcentaje = parseInt(fase?.porcentajeGanancia) || 0;

  //     if(fase?.presupuesto?.some((item) => item.aprobado == 1)){
  //       total = fase?.presupuesto?.find((item)=> item.aprobado == 1)?.precio || 0;
  //       total += total * (porcentaje / 100) || 0;
  //       return total;
  //     }else{
  //       let content = [
  //         ...(fase?.concepto || []),
  //       ];
  //       content?.forEach((item) => {
  //         total += item.precio || 0;
  //       });
  //       total += total * (porcentaje / 100) || 0;
  //       return total;
  //     }
  //   }
  // };

  const getTotal = () => {
    const porcentaje = parseInt(fase?.porcentajeGanancia) || 0;
    let total = 0;
  
    const calcularTotal = (items, cantidad = 1) => {
      return items.reduce((acc, item) => {
        if (item.horas) {
          return acc + item.precio * item.horas * cantidad;
        } else if (item.cantidad) {
          return acc + item.precio * item.cantidad * cantidad;
        } else {
          return acc + item.precio;
        }
      }, 0);
    };
  
    if (fase?.nombreUnidad) {
      const cantidad = parseInt(fase?.cantidad) || 0;
      const content = [
        ...(fase?.manoDeObra || []),
        ...(fase?.materiales || []),
        ...(fase?.concepto || []),
      ];
      total = calcularTotal(content, cantidad);
    } else if (fase?.presupuesto?.some((item) => item.aprobado == 1)) {
      total = fase.presupuesto.find((item) => item.aprobado == 1)?.precio || 0;
    } else {
      const content = [...(fase?.concepto || [])];
      total = calcularTotal(content);
    }
  
    total += total * (porcentaje / 100);
    return total;
  };

  
  useEffect(() => {
    if(fase?.presupuesto?.some((item) => item.aprobado == 1)){
      const dataProveedor = fase?.presupuesto?.find((item)=> item.aprobado == 1)?.nombre;
      const proveedor = dataProveedor?.razonSocial ? dataProveedor?.razonSocial : dataProveedor?.nombre ? dataProveedor?.nombre+" "+dataProveedor?.apellido : null;
      setProveedoredorPresupuesto(proveedor);
    }else{
      setProveedoredorPresupuesto(null);
    }
  },[fase]);

  return (
    <Card>
      <SoftBox p={2}>
        <SoftBox display="flex" alignItems="flex-start" justifyContent="space-between">
          <SoftBox display="flex" alignItems="center" >
            <SoftBox mr={2} display="flex" alignItems="center">
              <Icon>arrow_right</Icon>
              <SoftTypography variant="h6" color="primary">Nº{index + 1}</SoftTypography>
            </SoftBox>
            <SoftBox display="flex" alignItems="center">
              <Tooltip placement="top" title={`Nombre de la fase`}>
                <SoftBox>
                  <SoftInput 
                  value={fase?.nombre} 
                  onChange={(e) => handleChangeFase(e, fase.id)} 
                  name="nombre" 
                  error={fase?.nombre == ""}
                  disabled={fase?.certificadoActivo == true}
                  />
                </SoftBox>
              </Tooltip>
            </SoftBox>         
            {(!fase?.nombreUnidad && !fase?.certificadoActivo) ? (
                <SoftBox ml={2}>
                  {
                    fase?.presupuesto?.some((item) => item.aprobado == 1) ? (
                      <SoftBox display="flex" alignItems="center" flexDirection="column">
                        <SoftBadge
                          badgeContent={proveedorPresupuesto+" - Presupuesto Aprobado"}
                          color={"success"}
                          container
                        />
                      </SoftBox>
                    ) : (
                      <SoftBox display="flex" alignItems="center" flexDirection="column">
                        <SoftBadge
                          badgeContent={"Presupuestos Sin Aprobar ("+fase?.presupuesto?.length+")"}
                          color={"error"}
                          container
                        />
                      </SoftBox>
                    )
                  }
                </SoftBox>
            ) : (
                fase?.certificadoActivo == true && (
                  <Tooltip placement="top" title="La fase tiene un proceso de certificación">
                    <SoftBox ml={2} display="flex" alignItems="center" flexDirection="column">
                      <SoftBadge
                        badgeContent={"En proceso de Certificación"}
                        color={"info"}
                        container
                      />
                    </SoftBox>
                  </Tooltip>
                )
            )}
          </SoftBox>

          <SoftBox display="flex" alignItems="flex-end" flexDirection="column-reverse" gap={1}>
            <SoftBox display="flex" alignItems="flex-end">
              <SoftBox mr={2} display="flex" alignItems="center">
                <Tooltip placement="top" title={`Fecha inicio estimado`}>
                  <SoftBox
                    sx={{
                      width: "145px",
                    }}
                    display="flex" alignItems="flex-start" flexDirection="column"
                  >
                    <SoftTypography variant="button">Fecha Inicio</SoftTypography>
                    <SoftInput
                      type="date"
                      value={fase?.fechaInicioEstimado}
                      onChange={(e) => handleChangeFase(e, fase.id)}
                      error={fase?.fechaFinEstimado != "" && dayjs(fase?.fechaInicioEstimado).isAfter(dayjs(fase?.fechaFinEstimado))}
                      name="fechaInicioEstimado"
                      disabled={fase?.certificadoActivo == true}
                    />
                  </SoftBox>
                </Tooltip>
              </SoftBox>
              <SoftBox mr={2} display="flex" alignItems="center" >
                <Tooltip placement="top" title={`Fecha fin estimado`}>
                  <SoftBox
                    sx={{
                      width: "145px",
                    }}
                    display="flex" alignItems="flex-start" flexDirection="column"
                  >
                    <SoftTypography variant="button">Fecha Fin</SoftTypography>
                    <SoftInput
                      type="date"
                      value={fase?.fechaFinEstimado}
                      onChange={(e) => handleChangeFase(e, fase.id)}
                      error={fase?.fechaInicioEstimado !== "" && dayjs(fase?.fechaFinEstimado).isBefore(dayjs(fase?.fechaInicioEstimado))}
                      name="fechaFinEstimado"
                      disabled={fase?.certificadoActivo == true}
                    />
                  </SoftBox>
                </Tooltip>
              </SoftBox>
              {
                fase?.nombreUnidad && (
                  <>
                    <SoftBox mr={2} display="flex" alignItems="center">
                      <Tooltip placement="top" title={`Cantidad de ${fase?.nombreUnidad?.toLowerCase()}`}>
                        <SoftBox
                          sx={{
                            width: "120px",
                          }}
                        >
                          <SoftInput
                            type="number"
                            value={fase?.cantidad}
                            onChange={(e) => handleChangeFase(e, fase.id)}
                            name="cantidad"
                            icon={{
                              component: "shopping_cart",
                              direction: "left",
                            }}
                            disabled={fase?.certificadoActivo == true}
                          />
                        </SoftBox>
                      </Tooltip>
                    </SoftBox>                  
                  </>
                )
              }
              <SoftBox mr={2} display="flex" alignItems="center">
                <Tooltip placement="top" title="Porcentaje de ganancia">
                  <SoftBox
                    sx={{
                      width: "120px",
                    }}
                  >
                    <SoftInput
                      type="number"
                      value={fase?.porcentajeGanancia || 0}
                      onChange={(e) => handleChangeFase(e, fase.id)}
                      name="porcentajeGanancia"
                      icon={{
                        component: "percent",
                        direction: "right",
                      }}
                      disabled={fase?.certificadoActivo == true}
                    />
                  </SoftBox>
                </Tooltip>
              </SoftBox>

              <SoftBox  display="flex" alignItems="center" flexDirection="column">
                <SoftTypography variant="button">Subtotal</SoftTypography>
                <SoftBadge
                  badgeContent={(!fase?.nombreUnidad && getTotal() <= 0) ? "Sin especificar" : `$${formatMoneyPunto(getTotal())}`}
                  color={(!fase?.nombreUnidad && getTotal() <= 0) ? "error" : "success"}
                  container
                />
              </SoftBox>
            </SoftBox>

            <SoftBox display="flex" alignItems="center">
              <SoftBox mr={1}>
                <Tooltip placement="top" title="Eliminar fase">
                  <SoftButton
                    onClick={() => handleDelete(fase, index)}
                    circular
                    iconOnly
                    color="error"
                    disabled={fase?.certificadoActivo == true}
                  >
                    <Icon>delete</Icon>
                  </SoftButton>
                </Tooltip>
              </SoftBox>
              <SoftBox>
                <Tooltip placement="top" title="Editar fase">
                  <SoftButton
                    onClick={() => handleEdit(fase, index)}
                    circular
                    iconOnly
                    color="primary"
                    disabled={fase?.certificadoActivo == true}
                  >
                    <Icon>edit</Icon>
                  </SoftButton>
                </Tooltip>
              </SoftBox>
            </SoftBox>

          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

FaseItem.propTypes = {
  index: PropTypes.number.isRequired,
  fase: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleChangeFase: PropTypes.func.isRequired,
};

export default FaseItem;
