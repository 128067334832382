/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import { getUproColor } from "utils/colors";
import { formatMoneyPunto } from "utils/formatters";
import { makeNiceText } from "utils/formatters";

const ESTADOS = {
  "Pendiente": "Pendiente de pago",
  "En_Proceso": "En proceso de pago",
  "Pagado": "Pagado con exito",
  "Cancelado": "Cancelado",
}

const getRows = (data, handleOpenMenu) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            data={
              <Icon
                sx={{
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color: getUproColor("sistemasAmarillo"),
                }}
                fontSize="lg"
              >
                monetization_on
              </Icon>
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip title={ESTADOS[item?.estado]} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                  }}
                  color= {
                    item?.estado === "Pendiente" 
                    ? "warning"
                    : item?.estado === "En_Proceso" 
                    ? "info"
                    : item?.estado === "Pagado"
                    ? "success"
                    : item?.estado === "Cancelado"
                    ? "error"
                    : "secondary"
                  }
                  fontSize="lg"
                >
                  monetization_on
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        id: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.id}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {item?.id}
            </SoftTypography>
          </SoftBox>
        ),
        proyecto: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={item?.proyecto?.nombre}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {item?.proyecto?.nombre}
            </SoftTypography>
          </SoftBox>
        ),
        nombreCompleto: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={
              item?.proveedor?.nombre
                ? makeNiceText(item?.proveedor?.nombre + " " + item?.proveedor?.apellido, "nombre")
                : item?.proveedor?.razonSocial
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {
                item?.proveedor?.tipoProveedor == "Particular" ?
                makeNiceText(item?.proveedor?.nombre + " " + item?.proveedor?.apellido, "nombre")
                : item?.proveedor?.razonSocial ? item?.proveedor?.razonSocial : "Sin nombre"}
            </SoftTypography>
          </SoftBox>
        ),
        fechaPago: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={item?.fechaPago ? dayjs(item?.fechaPago).format("DD/MM/YYYY") : "Sin fecha"}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.fechaPago ? dayjs(item?.fechaPago).format("DD/MM/YYYY") : "Sin fecha"}
            </SoftTypography>
          </SoftBox>
        ),
        precio: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={item?.precio ? "$ "+formatMoneyPunto(item?.precio) : "$ 0"}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {item?.precio ? "$ "+formatMoneyPunto(item?.precio) : "$ 0"}
            </SoftTypography>
          </SoftBox>
        ),
        estado: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={item?.estado}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {item?.estado}
            </SoftTypography>
          </SoftBox>
        ),
        accion: (
          <SoftBox
            mr={2}
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <Tooltip title="Opciones" placement="top">
              <Icon
                sx={{ color: getUproColor("sistemasGris"), cursor: "pointer", mx: 0.5 }}
                fontSize="small"
                onClick={(e) => handleOpenMenu(e, item)}
              >
                more_vert
              </Icon>
            </Tooltip>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", width: "1%", align: "left" },
  { name: "proyecto", desc: "Proyecto", align: "left" },
  { name: "nombreCompleto", desc: "Nombre y Apellido / razon social", align: "left" },
  { name: "fechaPago", desc: "Fecha de Pago", align: "left" },
  { name: "precio", desc: "Importe", align: "left" },
  { name: "estado", desc: "Estado", align: "left" },
  { name: "accion", desc: " ", align: "center", width: "1%", noOrder: true },
];

export default { columns, getRows };
