// React
import PropTypes from "prop-types";

// @mui material components
import { Card, Grid, Icon, Tooltip } from "@mui/material";

import MiniStatisticsCard from "components/Cards/StatisticsCards/MiniStatisticsCard";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MenuContext } from "context/menuContext";
import { useContext, useEffect, useState } from "react";
import ModalNewFase from "./components/ModalNewFase";
import ModalCantidadFase from "./components/ModalCantidadFase";
import { formatMoneyPunto } from "utils/formatters";
import ModalDelete from "components/Modals/Delete";
import ModalEditFase from "./components/ModalEditarFase";
import SinDatos from "./../../../../components/Carteles/SinDatos/index";
import FaseItem from "./components/FaseItem";
import ModalNewFaseTerceros from "./components/ModalNewFaseTerceros";
import ModalEditarFaseTerceros from "./components/ModalEditarFaseTerceros";

export default function Fases({ proyecto, setProyecto, large }) {
  const { menu, handleClose } = useContext(MenuContext);
  const [modalNewFase, setModalNewFase] = useState(false);
  const [modalNewFaseTerceros,setModalNewFaseTerceros] = useState(false);
  const [modalCantidadFase, setModalCantidadFase] = useState(false);
  const [faseToAdd, setFaseToAdd] = useState(null);
  const [tipoAdd, setTipoAdd] = useState("");
  const [cantidadPlantilla,setCantidadPlantilla] = useState(false);

  const handleOpenNewFase = (tipo) => {
    setTipoAdd(tipo);
    setModalNewFase(true);
  };

  const handleOpenNewFaseTerceros = ()=>{
    setModalNewFaseTerceros(true);
  }

  const handleOpenCantidadPlantilla = () => {
    setCantidadPlantilla(true);
    setModalCantidadFase(true);
  }

  const handleAddFase = (dataFase) => {
    let listFases = proyecto?.fases ? [...proyecto?.fases] : [];
    listFases.push({ ...dataFase, id: new Date().getTime() });
    setProyecto({ ...proyecto, fases: listFases });
  };

  const [faseSelect, setFaseSelect] = useState({});
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalEditFaseTerceros,setOpenModalEditFaseTerceros] = useState(false);

  const handleEditFase = (fase) => {
    setFaseSelect(fase);
    if(fase?.nombreUnidad){
      setOpenModalEdit(true);
    }else{
      setOpenModalEditFaseTerceros(true);
    }
  };

  const handleDeleteFase = (fase,index) => {
    setFaseSelect({
      ...fase,
      index:index
    });
    setOpenModalDelete(true);
  };

  const handleDelete = (posicion) => {
    //filtar por posicion
    let fasesFiltro = proyecto?.fases?.filter((fase,index) => index != posicion);
    setProyecto({ ...proyecto, fases: fasesFiltro });
  };

  const getTotal = () => {
    let total = 0;
    proyecto?.fases?.forEach((fase) => {
      const cantidad = parseInt(fase?.cantidad) || 0;
      const porcentaje = parseInt(fase?.porcentajeGanancia) || 0;
      let content = fase?.manoDeObra?.concat(fase?.materiales) || [];
      let subtotal = 0;
      content?.forEach((item) => {
        if (item.horas) {
          subtotal += item.precio * item.horas * cantidad || 0;
        } else {
          subtotal += item.precio * item.cantidad * cantidad || 0;
        }
      });
      subtotal += subtotal * (porcentaje / 100) || 0;
      total += subtotal;
    });
    return total;
  };

  const getTotalTerceros = () => {
    let total = 0;
    proyecto?.fases?.forEach((fase) => {
      let content = fase?.concepto || [];
      content?.forEach((item) => {
        total += item.precio || 0;
      })
    });
    return total;
  }

  const getTotalNuevo = () => {
    let total = 0;

    proyecto?.fases?.forEach((fase) => {
      let subtotal = 0;
      const porcentaje = parseInt(fase?.porcentajeGanancia) || 0;
      const calcularTotal = (items, cantidad = 1) => {
        return items.reduce((acc, item) => {
          if (item.horas) {
            return acc + item.precio * item.horas * cantidad;
          } else if (item.cantidad) {
            return acc + item.precio * item.cantidad * cantidad;
          } else {
            return acc + item.precio;
          }
        }, 0);
      };
    
      if (fase?.nombreUnidad) {
        const cantidad = parseInt(fase?.cantidad) || 0;
        const content = [
          ...(fase?.manoDeObra || []),
          ...(fase?.materiales || []),
          ...(fase?.concepto || []),
        ];
        subtotal = calcularTotal(content, cantidad);
      } else if (fase?.presupuesto?.some((item) => item.aprobado == 1)) {
        subtotal = fase.presupuesto.find((item) => item.aprobado == 1)?.precio || 0;
      } else {
        const content = [...(fase?.concepto || [])];
        subtotal = calcularTotal(content);
      }
    
      subtotal += subtotal * (porcentaje / 100);
      total += subtotal;
    });
  
    return total;
  };

  //total sin ganancia 
  const getTotalCosto = () => {
    let total = 0;

    proyecto?.fases?.forEach((fase) => {
      let subtotal = 0;
      const calcularTotal = (items, cantidad = 1) => {
        return items.reduce((acc, item) => {
          if (item.horas) {
            return acc + item.precio * item.horas * cantidad;
          } else if (item.cantidad) {
            return acc + item.precio * item.cantidad * cantidad;
          } else {
            return acc + item.precio;
          }
        }, 0);
      };

      if (fase?.nombreUnidad) {
        const cantidad = parseInt(fase?.cantidad) || 0;
        const content = [
          ...(fase?.manoDeObra || []),
          ...(fase?.materiales || []),
          ...(fase?.concepto || []),
        ];
        subtotal = calcularTotal(content, cantidad);
      } else if (fase?.presupuesto?.some((item) => item.aprobado == 1)) {
        subtotal = fase.presupuesto.find((item) => item.aprobado == 1)?.precio || 0;
      } else {
        const content = [...(fase?.concepto || [])];
        subtotal = calcularTotal(content);
      }

      total += subtotal;
    });

    return total;
  }

  return (
    <SoftBox>
      {large && (
        <SoftBox display="flex" justifyContent="space-between" mb={2}>
          <SoftTypography variant="h6">{`Nuevo proyecto - Fases`}</SoftTypography>
          <SoftButton
            circular
            color="primary"
            onClick={(event) => {
              menu({
                open: event.currentTarget,
                align: "right",
                options: [
                  {
                    name: "Nueva fase propia",
                    icon: { icon: "add", color: "success" },
                    onClick: () => {
                      handleOpenNewFase("Nuevo");
                      handleClose();
                    },
                  },
                  {
                    name: "Nueva fase terceros",
                    icon: { icon: "person", color: "warning" },
                    onClick: () => {
                      handleOpenNewFaseTerceros();
                      handleClose();
                    },
                  },
                  {
                    name: "Plantilla",
                    icon: { icon: "file_copy", color: "info" },
                    onClick: () => {
                      handleOpenCantidadPlantilla();
                      handleClose();
                    },
                  },
                ],
                handleClose: () => {},
              });
            }}
          >
            <Icon>add</Icon>&nbsp;Agregar
          </SoftButton>
        </SoftBox>
      )}
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={3}>
          <MiniStatisticsCard
            title={{ text: "Materiales" }}
            count={`$${formatMoneyPunto(
              proyecto?.fases?.reduce((acc, fase) => {
                let total = 0;
                fase?.materiales?.forEach((material) => {
                  total += material.precio * material.cantidad * fase.cantidad;
                });
                return acc + total;
              }, 0) || 0
            )}`}
            icon={{ color: "dark", component: "shopping_cart" }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <MiniStatisticsCard
            title={{ text: "Mano de obra" }}
            count={`$${formatMoneyPunto(
              proyecto?.fases?.reduce((acc, fase) => {
                let total = 0;
                fase?.manoDeObra?.forEach((manoHobra) => {
                  total += manoHobra.precio * manoHobra.horas * fase.cantidad;
                });
                return acc + total;
              }, 0) || 0
            )}`}
            icon={{ color: "warning", component: "construction" }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <MiniStatisticsCard
            title={{ text: "Ganancias" }}
            count={`$${formatMoneyPunto((getTotalNuevo())-(getTotalCosto() || 0))}`}
            // count={`$${formatMoneyPunto(
            //   (getTotal() + getTotalTerceros()) -
            //     proyecto?.fases?.reduce((acc, fase) => {
            //       let total = 0;
            //       fase?.manoDeObra?.forEach((manoHobra) => {
            //         total += manoHobra.precio * manoHobra.horas * fase.cantidad;
            //       });
            //       return acc + total;
            //     }, 0) -
            //     proyecto?.fases?.reduce((acc, fase) => {
            //       let total = 0;
            //       fase?.materiales?.forEach((material) => {
            //         total += material.precio * material.cantidad * fase.cantidad;
            //       });
            //       return acc + total;
            //     }, 0) - 
            //     proyecto?.fases?.reduce((acc, fase) => {
            //       let total = 0;
            //       fase?.concepto?.forEach((concepto) => {
            //         total += concepto?.precio;
            //       });
            //       return acc + total;
            //     }, 0)  || 0
            // )}`}
            icon={{ color: "success", component: "paid" }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <MiniStatisticsCard
            title={{ text: "Total" }}
            // count={`$${formatMoneyPunto(getTotal() + getTotalTerceros())}`}
            count={`$${formatMoneyPunto(getTotalNuevo())}`}
            icon={{ color: "info", component: "attach_money" }}
          />
        </Grid>
      </Grid>

      <SoftBox py={3} display="flex" flexDirection="column" gap={2}>
        {proyecto?.fases?.length > 0 ? (
          proyecto.fases.map((fase, index) => (
            <FaseItem
              index={index}
              fase={fase}
              key={fase.id}
              handleEdit={() => handleEditFase(fase)}
              handleDelete={() => handleDeleteFase(fase,index)}
              handleChangeFase={(e, idItem) => {
                let fases = [...proyecto?.fases];
                let index = fases.findIndex((fase) => fase.id === idItem);
                fases[index] = { ...fases[index], [e.target.name]: e.target.value };
                setProyecto({ ...proyecto, fases });
              }}
            />
          ))
        ) : (
          <SinDatos />
        )}
      </SoftBox>

      <ModalNewFase
        open={modalNewFase}
        handleClose={() => {
          setModalNewFase(false);
          setFaseToAdd(null);
        }}
        guardarFase={(fase) => {
          setModalCantidadFase(true);
          setFaseToAdd(fase);
        }}
        tipo={tipoAdd}
        tipoMoneda={proyecto?.tipoMoneda}
      />

      <ModalNewFaseTerceros
      open={modalNewFaseTerceros}
      handleClose={()=>{
        setModalNewFaseTerceros(false);
        setFaseToAdd(null);
      }}
      guardarFase={(fase) => {
        handleAddFase(fase);
      }}
      tipoMoneda={proyecto?.tipoMoneda}
      />

      <ModalEditarFaseTerceros
      open={openModalEditFaseTerceros}
      handleClose={() => {
        setOpenModalEditFaseTerceros(false);
        setFaseSelect({});
      }}
      dataFase={faseSelect}
      guardarFase={(fase) => {
        let fases = [...proyecto?.fases];
        let index = fases.findIndex((fase) => fase.id === faseSelect.id);
        fases[index] = fase;
        setProyecto({ ...proyecto, fases });
        setOpenModalEditFaseTerceros(false);
        setFaseSelect({});
      }}
      tipoMoneda={proyecto?.tipoMoneda}
      />


      <ModalCantidadFase
        open={modalCantidadFase}
        handleClose={() => {
          setCantidadPlantilla(false);
          setModalCantidadFase(false);
          setFaseToAdd(null);
        }}
        fase={faseToAdd}
        guardarFase={(fase) => {
          setModalCantidadFase(false);
          setModalNewFase(false);
          setCantidadPlantilla(false);
          handleAddFase(fase);
          setFaseToAdd(null);
        }}
        plantilla={cantidadPlantilla}
      />
      <ModalEditFase
        open={openModalEdit}
        handleClose={() => {
          setOpenModalEdit(false);
          setFaseSelect({});
        }}
        dataFase={faseSelect}
        guardarFase={(fase) => {
          let fases = [...proyecto?.fases];
          let index = fases.findIndex((fase) => fase.id === faseSelect.id);
          fases[index] = fase;
          setProyecto({ ...proyecto, fases });
          setOpenModalEdit(false);
          setFaseSelect({});
        }}
        tipoMoneda={proyecto?.tipoMoneda}
      />
      <ModalDelete
        open={openModalDelete}
        handleClose={() => {
          setFaseSelect({});
          setOpenModalDelete(false);
        }}
        nombre={`la fase ${faseSelect?.nombre}`}
        functionDelete={() => {
          handleDelete(faseSelect?.index);
          setOpenModalDelete(false);
        }}
      />
    </SoftBox>
  );
}

Fases.propTypes = {
  proyecto: PropTypes.object,
  setProyecto: PropTypes.func,
  large: PropTypes.bool,
};
