// React
import PropTypes from "prop-types";

// @mui material components
import {
  Card,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Skeleton,
  Tooltip
} from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DataTable from "components/Tables/DataTable";
import { useEffect, useState } from "react";
import { formatMoneyPunto } from "utils/formatters";
import resumenData from "../../data/resumenData";
import { gql, useLazyQuery} from "@apollo/client";
import { getUproColor } from "utils/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
 
  maxHeight: "90%",
  width: { xs: "90%", sm: "60%", xxl: "50%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalCantidadFase({ open, handleClose, fase, guardarFase,plantilla }) {
  const [dataFase, setDataFase] = useState({
    cantidad: 0,
    porcentajeGanancia: 0,
  });
  const [rows, setRows] = useState([]);
  const { getRows, columns } = resumenData;
  const [plantillaSelect, setPlantillaSelect] = useState(null);

  useEffect(() => {
    if (fase) {
      let content = [
        ...(fase?.manoDeObra || []), 
        ...(fase?.materiales || []), 
        ...(fase?.concepto || [])
      ];  
      content = content?.sort((a, b) => {
        if (a.horas && b.horas) return 0;
        if (a.horas) return 1;
        if (b.horas) return -1;
        return 0;
      });
      setRows(getRows(content, dataFase.cantidad));
    }else{
      if(plantillaSelect){
      let content = [
        ...(plantillaSelect?.manoDeObra || []), 
        ...(plantillaSelect?.materiales || []), 
        ...(plantillaSelect?.concepto || [])
      ];
      content = content?.sort((a, b) => {
        if (a.horas && b.horas) return 0;
        if (a.horas) return 1;
        if (b.horas) return -1;
        return 0;
      });
      setRows(getRows(content, dataFase.cantidad));
      }
    }
  }, [fase, dataFase.cantidad, plantillaSelect]);

  useEffect(() => {
    setDataFase({
      cantidad: 0,
      porcentajeGanancia: 0,
    });
    if(open && plantilla){
      getPlantillas();
    }
  }, [open]);

  const getTotal = () => {
    let total = 0;
    // let content = fase ? fase?.manoDeObra?.concat(fase?.materiales) : plantillaSelect ? plantillaSelect?.manoDeObra?.concat(plantillaSelect?.materiales) : [];
    let content = [];
    if(fase){
      content = [
        ...(fase?.manoDeObra || []), 
        ...(fase?.materiales || []), 
        ...(fase?.concepto || [])
      ];
    }else{
      content = [
        ...(plantillaSelect?.manoDeObra || []), 
        ...(plantillaSelect?.materiales || []), 
        ...(plantillaSelect?.concepto || [])
      ];
    }
    content?.forEach((item) => {
      if (item.horas) {
        total += item.precio * item.horas * dataFase.cantidad || 0;
      } else if (item.cantidad) {
        total += item.precio * item.cantidad * dataFase.cantidad || 0;
      } else {
        total += item.precio || 0;
      }
    }
    );
    total += total * (dataFase?.porcentajeGanancia / 100) || 0;
    return total;
  };

  const [getPlantillas, { data: dataPlantillas, loading: loadingPlantillas }] = useLazyQuery(
    gql`
      query GetPlantillas($filter: JSON) {
        plantillasFases(filter: $filter) {
          id
          nombre
          descripcion
          nombreUnidad
          manoDeObra {
            id
            tipoMoneda
            precio
            cotizacion
            nombre
            horas
          }
          materiales {
            id
            nombre
            cantidad
            tipoMoneda
            precio
            cotizacion  
            producto{
              id
              nombre
              precio
            }
          }
        }
      }
    `
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPlantilla, setSelectedPlantilla] = useState(null);
  const [openPlanillaMenu, setOpenPlanillaMenu] = useState(false);

  const handleOpenPlanillaMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPlanillaMenu(true);
  };
  const handleClosePlanillaMenu = () => {
    setAnchorEl(null);
    setOpenPlanillaMenu(false);
  };

  const renderPlantillaMenu = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(openPlanillaMenu)}
      onClose={handleClosePlanillaMenu}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          maxHeight: "300px",
          overflowY: "scroll",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
        },
      }}
    >
      {dataPlantillas?.plantillasFases?.map((plantilla, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            setPlantillaSelect(plantilla);
            setSelectedPlantilla(plantilla.id);
            handleClosePlanillaMenu();
          }}
          sx={{
            minWidth: { xs: "75vw", sm: "auto" },
          }}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <SoftBox mr={2}>
              <SoftTypography variant="p">{plantilla.nombre}</SoftTypography>
            </SoftBox>
            <SoftBox>
              <Icon
                fontSize="small"
                sx={{
                  verticalAlign: "middle",
                  color:
                    plantilla.id === selectedPlantilla
                      ? getUproColor("sistemasAmarillo")
                      : getUproColor("sistemasGris"),
                }}
              >
                auto_awesome_mosaic
              </Icon>
            </SoftBox>
          </SoftBox>
        </MenuItem>
      ))}
    </Menu>
  );

  const resetModal = () => {
    setSelectedPlantilla(null);
    setPlantillaSelect(null);
    setOpenPlanillaMenu(false);
    setAnchorEl(null);
    setRows([]);
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Cantidad de items</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" 
                    onClick={() => {
                      handleClose();
                      resetModal();
                    }}
                    sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            {
              plantilla && (
                <SoftBox my={1}>
                  <Grid container spacing={2}>
                    <>
                      {renderPlantillaMenu}
                      <Grid item xs={12} sm={12}>
                        <SoftBox
                          display="flex"
                          alignItems="center"
                          onClick={
                            dataPlantillas?.plantillasFases?.length > 0 ? handleOpenPlanillaMenu : null
                          }
                          sx={{ maxWidth: "max-content" }}
                        >
                          <Icon color="primary"> auto_awesome_mosaic</Icon>
                          <SoftTypography
                            variant="h6"
                            sx={{
                              cursor: "pointer",
                            }}
                            ml={1}
                          >
                            {loadingPlantillas ? (
                              <Skeleton width={100} />
                            ) : dataPlantillas?.plantillasFases?.find(
                                (plantilla) => plantilla.id === selectedPlantilla
                              )?.nombre ? (
                              dataPlantillas?.plantillasFases?.find(
                                (plantilla) => plantilla.id === selectedPlantilla
                              ).nombre
                            ) : dataPlantillas?.plantillasFases?.length > 0 ? (
                              "Seleccione una Plantilla"
                            ) : (
                              "No hay plantillas disponibles"
                            )}
                          </SoftTypography>
                          <Icon color="dark">
                            {openPlanillaMenu ? "arrow_drop_up" : "arrow_drop_down"}
                          </Icon>
                        </SoftBox>
                      </Grid>
                    </>
                  </Grid>
                </SoftBox>
              )
            }

            <SoftBox mb={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="cantidad">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Cantidad {fase?.nombreUnidad ? `(${fase?.nombreUnidad})` : plantillaSelect?.nombreUnidad ? `(${plantillaSelect?.nombreUnidad})` : ""}
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="cantidad"
                    placeholder="Ingrese la cantidad"
                    type="number"
                    name="cantidad"
                    icon={{
                      component: "shopping_cart",
                      direction: "left",
                    }}
                    value={dataFase?.cantidad || ""}
                    error={dataFase?.cantidad != "" && dataFase?.cantidad <= 0}
                    onChange={(e) => {
                      setDataFase({
                        ...dataFase,
                        cantidad: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="porcentajeGanancia">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Margen ganancia (%)
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="porcentajeGanancia"
                    placeholder="Ingrese el porcentaje de ganancia"
                    type="number"
                    name="porcentajeGanancia"
                    icon={{
                      component: "percent",
                      direction: "left",
                    }}
                    value={dataFase?.porcentajeGanancia || ""}
                    error={dataFase?.porcentajeGanancia != "" && dataFase?.porcentajeGanancia < 0}
                    onChange={(e) => {
                      setDataFase({
                        ...dataFase,
                        porcentajeGanancia: e.target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox mb={3}>
              <SoftBox mb={2}>
                <SoftTypography variant="h6">Materiales, Mano de obra y Conceptos</SoftTypography>
              </SoftBox>
              <SoftBox>
                <SoftBox
                  mb={2}
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                    minHeight: "100px",
                  }}
                >
                  <DataTable columns={columns} rows={rows} />
                </SoftBox>
                <SoftBox display="flex" justifyContent="flex-end">
                  <SoftBox>
                    <SoftTypography variant="h6" fontWeight="bold">
                      Total
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox ml={2}>
                    <SoftTypography variant="h6" fontWeight="bold">
                      {`$${formatMoneyPunto(getTotal())}`}
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="flex-end"
              alignItems="center"
            >
              <SoftBox
                display={{
                  xs: "flex-row",
                  sm: "flex",
                }}
                justifyContent="end"
                alignItems="center"
              >
                <SoftBox
                  mr={{
                    xs: 0,
                    sm: 2,
                  }}
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="primary"
                    circular
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                    onClick={()=>{
                      handleClose();
                      resetModal();
                    }}
                  >
                    <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                    &nbsp;Cancelar
                  </SoftButton>
                </SoftBox>
                <SoftBox
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="sistemasGris"
                    circular
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                    onClick={() => {
                      if(plantilla){
                        guardarFase({...plantillaSelect, ...dataFase});
                      }else{
                        guardarFase({...fase, ...dataFase});
                      }
                      resetModal();
                    }}
                    disabled={((dataFase?.cantidad <= 0 || dataFase?.porcentajeGanancia < 0) && !plantilla) || (plantilla && (!plantillaSelect || (dataFase?.cantidad <= 0 || dataFase?.porcentajeGanancia < 0)))}
                  >
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                    &nbsp;Guardar
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalCantidadFase.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  fase: PropTypes.object,
  guardarFase: PropTypes.func,
  plantilla: PropTypes.bool,
};
