// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// @mui material components
import TableRow from "@mui/material/TableRow";

// Kaizen Dashboard base styles
import { Icon, MenuItem, Select, Tooltip } from "@mui/material";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import { getUproColor } from "utils/colors";
import { formatMoneyPunto, makeNiceText } from "utils/formatters";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import { useEffect, useState } from "react";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

function TableItem({ material,cantidad, handleUpdateMaterial,cotizaciones,ordenTipo }) {
  const { light } = colors;
  const { borderWidth } = borders;
  const [materialData, setMaterialData] = useState({
    ...material,
    tipoMonedaCompra: material?.tipoMoneda,
    cotizacionCompra: material?.cotizacion,
  });

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setMaterialData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    handleUpdateMaterial(materialData);
  }, [materialData]);

  const validarFila = ()=>{
    let contadorFilasValidas = 0;
    
    if(material?.producto?.nombre){
      if((materialData?.stockUsado || materialData?.cantComprada) && materialData?.precioCompra && materialData?.tipoMonedaCompra && materialData?.cotizacionCompra){
        contadorFilasValidas++;
      }  
    }else{
      if(materialData?.cantComprada){
        contadorFilasValidas++;
      } 
    }
   
    return contadorFilasValidas > 0 
  }
  

  return (
    <TableRow hover 
      selected={validarFila()}
    >
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        <SoftBox
          display="flex"
          alignItems="center"
          sx={{
            cursor: "pointer",
          }}
        >
          <SoftBox pl={3} mr={2} >
            <Tooltip title={material?.horas ? "Mano de obra" : material?.cantidad ? "Material" : "Concepto"} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color: material?.horas ? getUproColor("sistemasGris") :  material?.cantidad ? getUproColor("sistemasAmarillo") : getUproColor("sistemasNegro"),
                }}
                  fontSize="lg"
                >
                  {material?.horas ? "schedule" : material?.cantidad ? "inventory_2" : "inventory_1"}
                </Icon>
              </Tooltip>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
        lineHeight={1}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {material?.producto?.nombre ? material?.producto?.nombre : material?.nombre}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
        {material?.horas ? `${material?.horas * cantidad}hs` : material?.cantidad ? `${material?.cantidad * cantidad}u` : "1"}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
        {material?.ordenPrevia ? `${material?.restante}u` : material?.horas ? `${material?.horas * cantidad}hs` : material?.cantidad ? `${material?.cantidad * cantidad}u` : "1"}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {material?.tipoMoneda ||  "-"}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
         {`$${formatMoneyPunto(material?.precio || 0)}`}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {`$${formatMoneyPunto(material?.cotizacion || 0)}`}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {material?.horas ? `$${formatMoneyPunto(material?.precio * material?.horas * cantidad)}` : material?.cantidad ? `$${formatMoneyPunto(material?.precio * material?.cantidad * cantidad)}` : `$${formatMoneyPunto(material?.precio || 0)}`}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"center"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <Tooltip 
        title={(materialData?.cantComprada && materialData?.cantComprada !== "" && 
          (
            (
              material?.ordenPrevia ? 
              ((Number(materialData?.cantComprada || 0) + Number(materialData?.stockUsado || 0)) > (material?.restante))
                :
              (Number(materialData?.cantComprada || 0) + Number(materialData?.stockUsado || 0)) > (material?.cantidad * cantidad) 
            ) || (
              ordenTipo == "Concepto" &&
              materialData?.cantComprada > 1
            )
          )
        ) ? "La cantidad ingresada es superior a la cantidad necesaria" : ""}
        placement="top">
         <SoftInput
         name="cantComprada"
         value={materialData?.cantComprada ?? ""}
         onChange={(e)=>{
          if(e.target.value >= 0){
            handleChanges(e);
          }
         }}
         type="number"
         error={materialData?.cantComprada && materialData?.cantComprada !== "" && 
          (
          (
            material?.ordenPrevia ? 
            ((Number(materialData?.cantComprada || 0) + Number(materialData?.stockUsado || 0)) > (material?.restante))
              :
            (Number(materialData?.cantComprada || 0) + Number(materialData?.stockUsado || 0)) > (material?.cantidad * cantidad) 
          ) || (
            ordenTipo == "Concepto" &&
            materialData?.cantComprada > 1
          )
          )
        }
         />
          </Tooltip>
      </SoftBox>
      {
        ordenTipo != "Concepto" && (
          <>
            <SoftBox
              component="td"
              p={1}
              textAlign={"center"}
              borderBottom={`${borderWidth[1]} solid ${light.main}`}
              sx={{
                verticalAlign: "center",
              }}
            >
              <SoftInputNumber
              name="precioCompra"
              value={materialData?.precioCompra ?? ""}
              onChange={handleChanges}
              />
            </SoftBox>
            <SoftBox
              component="td"
              p={1}
              borderBottom={`${borderWidth[1]} solid ${light.main}`}
              sx={{
                verticalAlign: "center",
              }}
            >
              <Select
              name="tipoMonedaCompra"
              value={materialData?.tipoMonedaCompra || ""}
              onChange={(event)=>{
                  setMaterialData({
                      ...materialData,
                      tipoMonedaCompra: event.target.value,
                      cotizacionCompra: cotizaciones?.find(ctz => ctz?.nombre === event.target.value)?.venta || (event.target.value === "Pesos" ? 1 : 0),
                  })
              }}
              >
                  <MenuItem value="Pesos">Pesos</MenuItem>
                  {
                      cotizaciones?.map((ctz) => {
                          return (
                              <MenuItem key={ctz?.nombre} value={ctz?.nombre}>
                                  {ctz?.nombre}
                              </MenuItem>
                          )
                      }) 
                  }
              </Select>
            </SoftBox>
            <SoftBox
              component="td"
              p={1}
              textAlign={"center"}
              borderBottom={`${borderWidth[1]} solid ${light.main}`}
              sx={{
                verticalAlign: "center",
              }}
            >
              <SoftInputNumber
              name="cotizacionCompra"
              value={materialData?.cotizacionCompra ?? ""}
              onChange={handleChanges}
              />
            </SoftBox>
          </>
        )
      }
      <SoftBox
        component="td"
        p={1}
        textAlign={"center"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            { ordenTipo != "Concepto" ? 
              (materialData?.cantComprada && materialData?.precioCompra) ? "$ "+formatMoneyPunto(materialData?.cantComprada * materialData?.precioCompra) : "-"
              : (materialData?.cantComprada && material?.precio) ? "$ "+formatMoneyPunto(materialData?.cantComprada * material?.precio) : "-"
          }
        </SoftTypography>
      </SoftBox>
    </TableRow>
  );
}

// Typechecking props of the TableItem
TableItem.propTypes = {
  material: PropTypes.object.isRequired,
  cantidad: PropTypes.number,
  handleUpdateMaterial: PropTypes.func,
  cotizaciones: PropTypes.array,
  ordenTipo: PropTypes.string
 };

export default TableItem;
