// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Kaizen Dashboard components
import { Tooltip } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftCircularProgress from "components/SoftCircularProgress";
import SoftProgress from "components/SoftProgress";
import SoftTypography from "components/SoftTypography";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

function ProyectoItem({
  proyecto,
  handleEdit,
  handleCertificar,
  handlePresupuesto,
  handleDelete,
  handleRestaurar,
  handleReportes,
}) {
  const [certificadoHabilitado, setCertificadoHabilitado] = useState(false);

  useEffect(() => {
    if(proyecto){
      validarCertificado();
    } else{
      setCertificadoHabilitado(false);
    }
  },[proyecto]);

  const validarCertificado = ()=>{
    if(proyecto?.certificado?.length > 0){
      setCertificadoHabilitado(true);
    }else{
      if(proyecto?.presupuestoAprobadoLast?.fases?.some((fase)=> fase?.nombreUnidad === null)){
        let errores = 0;
        proyecto?.presupuestoAprobadoLast?.fases?.forEach((fase)=>{
          if(fase?.nombreUnidad === null && fase?.presupuesto?.every((presupuesto)=> presupuesto?.aprobado == false)){
            errores++;
          }
        });
        if(errores > 0){
          setCertificadoHabilitado(false);
        }else{
          setCertificadoHabilitado(true);
        }
      }else{
        setCertificadoHabilitado(true);
      }
    }
  }

 
  return (
    <Card>
      <SoftBox p={2}>
        <SoftBox display="flex" alignItems="center" justifyContent="space-between">
          <SoftBox display="flex">
            <Tooltip title={proyecto?.estado} placement="top">
              <SoftBox
                display="flex"
                alignItems="center"
                mr={2}
                sx={{
                  cursor: "pointer",
                }}
              >
                <SoftCircularProgress
                  color={
                    proyecto?.estado === "Pendiente"
                      ? "primary"
                      : proyecto?.estado === "Finalizado"
                      ? "success"
                      : "error"
                  }
                  icon={
                    proyecto?.estado === "Pendiente"
                      ? "pending_actions"
                      : proyecto?.estado === "Finalizado"
                      ? "done"
                      : proyecto?.estado === "Cancelado"
                      ? "error"
                      : null
                  }
                  value={0}
                />
              </SoftBox>
            </Tooltip>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="button">
                {`Proyecto Nº ${proyecto?.id}`} -{" "}
                {proyecto?.cliente
                  ? "Cliente " + proyecto?.cliente?.nombre + " " + proyecto?.cliente?.apellido
                  : "Proyecto Propio"}{" "}
              </SoftTypography>
              <SoftBox display="flex" alignItems="center">
                <SoftTypography variant="h5" fontWeight="bold" color={"primary"}>
                  {proyecto.nombre}
                </SoftTypography>
                {proyecto?.estado === "Pendiente" && (
                  <SoftBox ml={1}>
                    <SoftBadge
                      badgeContent={`${proyecto?.presupuestos?.length} presupuesto${
                        proyecto?.presupuestos?.length > 1 ? "s" : ""
                      }`}
                      color="info"
                    />
                  </SoftBox>
                )}
                {proyecto?.estado !== "Pendiente" && (
                  <SoftBox ml={1}>
                    <SoftBadge badgeContent={"2 fases restantes"} color="dark" />
                  </SoftBox>
                )}
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftBox display="flex">
            {proyecto?.estado === "Cancelado" ? (
              <SoftBox>
                <Tooltip placement="top" title="Restaurar proyecto">
                  <SoftButton onClick={handleRestaurar} circular iconOnly color="info">
                    <Icon>settings_backup_restore</Icon>
                  </SoftButton>
                </Tooltip>
              </SoftBox>
            ) : (
              <>
                {proyecto?.estado === "Pendiente" && !proyecto?.presupuestoAprobado && (
                  <SoftBox mr={2}>
                    <Tooltip placement="top" title="Ver presupuestos">
                      <SoftButton onClick={handlePresupuesto} circular iconOnly color="warning">
                        <Icon>rule_folder</Icon>
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                )}
                {proyecto?.estado !== "Pendiente" && (
                  <>
                    <SoftBox mr={2}>
                      <Link
                        to={`/ordenes/${proyecto?.id}`}
                        // state={{
                        //   idProyecto: proyecto?.id,
                        //   idPresupuesto: proyecto?.presupuestoAprobado?.id,
                        //   numero: proyecto?.presupuestoAprobado?.numero,
                        // }}
                      >
                        <Tooltip placement="top" title="Orden de compra">
                          <SoftButton onClick={handleCertificar} circular iconOnly color="warning">
                            <Icon>attach_money</Icon>
                          </SoftButton>
                        </Tooltip>
                      </Link>
                    </SoftBox>
                    {/* {
                      certificadoHabilitado && ( */}
                        <SoftBox mr={2}>
                          <Tooltip placement="top" title={certificadoHabilitado ? "Certificar" : "Hay presupuestos sin aprobar"}>
                            <Link
                              to={certificadoHabilitado ? `/certificados/nuevoCertificado` : "#"}
                              state={{
                                idProyecto: proyecto?.id,
                                idPresupuesto: proyecto?.presupuestoAprobadoLast?.id,
                                numero: proyecto?.presupuestoAprobadoLast?.numero,
                              }}
                            >
                                <SoftButton onClick={handleCertificar} circular iconOnly color="success" disabled={!certificadoHabilitado} >
                                  <Icon>request_quote</Icon>
                                </SoftButton>
                            </Link>
                          </Tooltip>
                        </SoftBox>
                      {/* )
                    } */}
                  </>
                )}
                {(proyecto?.estado === "Finalizado" || proyecto?.estado === "En_Proceso") && (
                  <SoftBox mr={2}>
                    <Tooltip placement="top" title="Ver reportes">
                      <SoftButton
                        onClick={handleReportes}
                        circular
                        iconOnly
                        color="info"
                      >
                        <Icon>bar_chart</Icon>
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                )}
                {proyecto?.estado === "Pendiente" && (
                <SoftBox mr={2}>
                  <Tooltip placement="top" title="Descartar proyecto">
                    <SoftButton onClick={handleDelete} circular iconOnly color="error">
                      <Icon>delete</Icon>
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
                )}
                <SoftBox>
                  <Tooltip placement="top" title="Editar datos del proyecto">
                    <SoftButton
                      onClick={() => handleEdit("Detalles")}
                      circular
                      iconOnly
                      color="sistemasGris"
                    >
                      <Icon>edit</Icon>
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
                {proyecto?.estado !== "Finalizado" && proyecto?.presupuestoAprobado && (
                  <SoftBox ml={2}>
                    <Link
                      to={`/proyectos/editarPresupuesto`}
                      state={{
                        idProyecto: proyecto?.id,
                        idPresupuesto: proyecto?.presupuestoAprobadoLast.id,
                      }}
                    >
                      <Tooltip placement="top" title="Editar presupuesto">
                        <SoftButton onClick={() => handleEdit("Fases")} circular color="primary">
                          Editar &nbsp;
                          <Icon>arrow_forward</Icon>
                        </SoftButton>
                      </Tooltip>
                    </Link>
                  </SoftBox>
                )}
              </>
            )}
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

ProyectoItem.propTypes = {
  proyecto: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleCertificar: PropTypes.func.isRequired,
  handlePresupuesto: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleRestaurar: PropTypes.func.isRequired,
  handleReportes: PropTypes.func.isRequired,
};

export default ProyectoItem;
